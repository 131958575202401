@import 'palette';
:root {
  @each $colorname, $palette in $palettes {
    @each $key, $value in $palette {
      $keyname: '--palette-' + $colorname + '-' + $key;
      #{$keyname}: #{$value};
    }
  }
}

:root,
.theme-light,
.disableThemeOverride {
  color-scheme: light; // window color scheme, for scrollbars and native elements

  //some primary colors
  --color-primary: var(--palette-blue-main);
  --color-brand: var(--palette-blue-main);
  --color-primary-gradient: var(--palette-blue-70);

  //most used colors
  --color-white: var(--palette-white-main);
  --color-black: var(--palette-black-main);
  --color-green: var(--palette-green-60);
  --color-red: var(--palette-red-50);
  --color-yellow: var(--palette-yellow-main);
  --color-gray: var(--palette-gray-main);
  --color-orange: var(--palette-orange-main);

  //for text, levels work as prioritys. Lower means darker.
  --color-text-l0: var(--palette-gray-90); //header
  --color-text-l1: var(--palette-gray-70); // content
  --color-text-l2: var(--palette-gray-60); // descripion
  --color-text-l3: var(--palette-gray-50); // disabled
  --color-text-white: var(--palette-white-main);
  --color-text-highlight: var(--palette-yellow-main);
  --color-text-primary: var(--palette-blue-main);

  --color-text-link: var(--palette-blue-60);
  --color-text-link-hover: var(--palette-blue-70);

  --color-divider: var(--palette-black-alpha20);
  --color-shadow: var(--palette-black-alpha20);
  --color-grid: var(--palette-black-alpha10);

  // levels here work like z-index / elevation of card
  --color-background-default: var(--palette-gray-20); //base
  --color-background-l0: var(--palette-white-main); //card
  --color-background-l1: var(--palette-gray-10); //above card
  --color-background-l2: var(--palette-gray-30);
  --color-background-activated: var(--palette-gray-10);
  --color-background-selected: var(--palette-gray-20);
  --color-background-hover: var(--palette-blue-10);
  --color-background-input: var(--palette-white-main);
  --color-background-inputotp: var(--palette-gray-30);
  --color-background-modal: var(--palette-gray-alpha20);
  --color-background-hero: var(--palette-blue-20);
  --color-background-toggle: var(--palette-blue-30);
  --color-background-stepper: var(--palette-gray-20);

  --color-background-warning: var(--palette-yellow-alpha30);
  --color-background-success: var(--palette-green-alpha20);
  --color-background-danger: var(--palette-red-alpha20);
  --color-background-info: var(--palette-blue-10);

  --color-scrollbar-background: var(--palette-gray-30);
  --color-scrollbar-thumb: var(--palette-gray-40);

  /* binance theme colors */
  --color-binance-primary: var(--palette-binance-primary);
  --color-binance-black: var(--palette-binance-black);

  /* moneycontrol theme colors */
  --color-moneycontrol-primary: var(--palette-moneycontrol-primary);
  --color-moneycontrol-blue: var(--palette-moneycontrol-blue);
  --color-moneycontrol-lightblue: var(--palette-moneycontrol-lightblue);
  --color-moneycontrol-lightgrey: var(--palette-moneycontrol-lightgrey);
  --color-moneycontrol-orange: var(--palette-moneycontrol-orange);
  --color-moneycontrol-green: var(--palette-moneycontrol-green);
  --color-moneycontrol-darkgreen: var(--palette-moneycontrol-darkgreen);
  --color-moneycontrol-grey: var(--palette-moneycontrol-grey);
  --color-moneycontrol-basemarket: var(--palette-moneycontrol-alpha50);
  --color-moneycontrol-header-background: var(--palette-white-main);

  --color-vendors-facebook: var(--palette-vendors-facebook);
  --color-vendors-whatsapp: var(--palette-vendors-whatsapp);
  --color-vendors-telegram: var(--palette-vendors-telegram);
  --color-vendors-twitter: var(--palette-vendors-twitter);

  /* special account / STFM account */
  --color-specialaccount-primary: var(--palette-gray-80);

  //overrides
  --color-header-border: var(--palette-black-alpha20);
  --color-header-signup-btn: var(--palette-yellow-main);
  --color-header-signup-btn-hover: var(--palette-yellow-70);
  --color-header-shadow: var(--palette-black-alpha20);

  --color-outer-header-background: var(
    --palette-white-main
  ); //for the landing page navbar - white color
  --color-outer-header-text: var(--palette-gray-60);

  --color-inner-header-text: var(
    --palette-white-main
  ); //the default text color of the header in the main products
  --color-inner-header-icon: var(--palette-white-alpha70);
  --color-inner-header-background: var(--palette-blue-main);
  --color-inner-header-btn-opened: var(--palette-black-alpha10);
  --color-inner-header-btn-selected: var(--palette-blue-80);
  --color-inner-header-btn-hover: var(--palette-black-alpha20);
  --color-inner-header-highlight-accent: var(--palette-yellow-30);
  --color-inner-header-popover: var(--palette-white-main);

  --color-footer-background: var(--palette-white-main);

  --color-table-row-hover: var(--palette-black-alpha05);
  --color-table-row-green-overlay: var(--palette-green-10);
  --color-table-row-red-overlay: var(--palette-red-10);
  --color-table-row-odd: var(--palette-gray-10);
  --color-table-row-even: var(--palette-white-main);

  --color-tradingView-background: var(--palette-white-main);

  --color-landing-hero-background: var(--palette-blue-main);

  --color-otp-input-background: ;

  //disabled input
  --color-disabled-input-bg: var(--palette-gray-20);
  --color-disabled-input-border: var(--palette-gray-30);
}
